import { graphql, Link } from "gatsby";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function ListingTemplate({ data, pageContext, location }) {
  // console.log(location);
  function processOpeningHours(openingHoursString) {
    if (openingHoursString) {
      const openingHoursArray = JSON.parse(openingHoursString);
      if (Array.isArray(openingHoursArray)) {
        return (
          <ul>
            {openingHoursArray.map((day, i) => (
              <li key={`oh-${i}`}>{day}</li>
            ))}
          </ul>
        );
      }
      return false;
    }
    return false;
  }

  // console.log({ pageContext });
  const listing = data.listing.data;
  const openingHours = processOpeningHours(listing?.Opening_Hours);
  const { citySlug, city } = pageContext;
  return (
    <>
      <section className="block">
        <ul className="breadcrumb">
          <li>
            <Link to="/">&#8962; Home</Link>
          </li>
          <li>
            <Link to={`/${citySlug}/`}>{city}</Link>
          </li>
          <li>
            <Link to={`/${citySlug}/${listing.Area_Slug}/`}>
              {listing.Area}
            </Link>
          </li>
        </ul>
      </section>
      <section className="block listing">
        <ul>
          {listing.Name && (
            <li>
              <strong>{listing.Name}</strong>
            </li>
          )}
          {listing.Korean_Name && (
            <li>
              {listing.Korean_Name}
              <CopyToClipboard text={listing.Korean_Name}>
                <button type="button" className="inline-button">
                  Copy
                </button>
              </CopyToClipboard>
            </li>
          )}
        </ul>
        <ul>
          {listing.Formatted_Address && <li>{listing.Formatted_Address}</li>}

          {listing.Korean_Formatted_Address && (
            <li>
              <span>{listing.Korean_Formatted_Address}</span>
              <CopyToClipboard text={listing.Korean_Formatted_Address}>
                <button type="button" className="inline-button">
                  Copy
                </button>
              </CopyToClipboard>
            </li>
          )}
        </ul>
        <ul>
          {listing.Website && (
            <li>
              <a href={listing.Website} target="_blank" rel="noreferrer">
                Website &#10697;
              </a>
            </li>
          )}
          {listing.Naver_Maps_URL && (
            <li>
              <a href={listing.Naver_Maps_URL} target="_blank" rel="noreferrer">
                Naver Maps &#10697;
              </a>
            </li>
          )}
          {listing.Google_Maps_URL && (
            <li>
              <a
                href={listing.Google_Maps_URL}
                target="_blank"
                rel="noreferrer"
              >
                Google Maps &#10697;
              </a>
            </li>
          )}
          {/* {listing.Must && <li className="must"> Must!</li>} */}
          {/* {listing.Shortlist && <li className="must"> Shortlist!</li>} */}
        </ul>

        {openingHours && (
          <ul>
            <li>{openingHours}</li>
          </ul>
        )}
        {listing.Notes && (
          <ul>
            <li>
              Note:
              <br />
              {listing.Notes}
            </li>
          </ul>
        )}
      </section>
    </>
  );
}

export const query = graphql`
  query ($CID: String!) {
    listing: airtable(data: { CID: { eq: $CID } }) {
      data {
        Area
        Area_Slug
        Formatted_Address
        Korean_Formatted_Address
        Korean_Name
        Name
        International_Phone_Number
        Google_Maps_URL
        Place_ID
        Opening_Hours
        Notes
        Shortlist
        Types
        Website
        Naver_Maps_URL
        Must
      }
    }
  }
`;
